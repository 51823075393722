var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState, useRef, useMemo } from 'react';
import MobileDetect from 'mobile-detect';
import './InlineDownloadButtons.sass';
import AppStoreDownload from '../../assets/img_download_appstore.svg';
import GooglePlayDownload from '../../assets/img_download_googleplay.svg';
import WebappDownload from '../../assets/img_download_webapp.svg';
var icons = {
    applestore: {
        ratio: 146.95 / 54,
        source: AppStoreDownload,
        url: 'https://apps.apple.com/tw/app/id1563987988',
    },
    googleplay: {
        ratio: 181.34 / 54,
        source: GooglePlayDownload,
        url: 'https://play.google.com/store/apps/details?id=app.qubic.wallet',
    },
    webapp: {
        ratio: 146.95 / 54,
        source: WebappDownload,
        url: 'https://wallet.qubic.app',
    },
};
export function useContainerDimensions(myRef) {
    var _a = useState({ width: 0, height: 0 }), dimensions = _a[0], setDimensions = _a[1];
    useEffect(function () {
        var getDimensions = function () { return ({
            width: (myRef && myRef.current.offsetWidth) || 0,
            height: (myRef && myRef.current.offsetHeight) || 0,
        }); };
        var handleResize = function () {
            setDimensions(getDimensions());
        };
        if (myRef.current) {
            setDimensions(getDimensions());
        }
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef]);
    return dimensions;
}
var InlineDownloadButtons = function (_a) {
    var maxHeight = _a.maxHeight, spacing = _a.spacing;
    var componentRef = useRef(null);
    var width = useContainerDimensions(componentRef).width;
    var shownButtons = useMemo(function () {
        var buttons = [];
        var md = new MobileDetect(window.navigator.userAgent);
        var os = md.os();
        if (os === 'iOS' || os === 'iPadOS') {
            buttons.push('applestore');
        }
        else if (os === 'AndroidOS') {
            buttons.push('googleplay');
        }
        else {
            buttons.push('applestore');
            buttons.push('googleplay');
        }
        buttons.push('webapp');
        return buttons;
    }, []);
    var totalRatio = shownButtons.reduce(function (result, current) { return result += icons[current].ratio; }, 0);
    var finalHeight = (width - (spacing * (shownButtons.length - 1))) / totalRatio;
    console.log('h', finalHeight);
    return (_jsx("div", __assign({ ref: componentRef, className: "download-button-group", style: { height: maxHeight > finalHeight ? finalHeight : maxHeight } }, { children: width > 0 && shownButtons.map(function (button, index) { return (_jsx("a", __assign({ href: icons[button].url, style: {
                marginRight: index !== shownButtons.length - 1 ? spacing : 0
            }, target: "_blank", rel: "noreferrer" }, { children: _jsx("img", { src: icons[button].source, alt: "Download Icon" }, void 0) }), button)); }) }), void 0));
};
export default InlineDownloadButtons;
