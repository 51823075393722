export var ChainNetwork;
(function (ChainNetwork) {
    ChainNetwork["mainnet"] = "mainnet";
    ChainNetwork["ropsten"] = "ropsten";
    ChainNetwork["rinkeby"] = "rinkeby";
    ChainNetwork["polygon"] = "polygon";
    ChainNetwork["mumbai"] = "mumbai";
})(ChainNetwork || (ChainNetwork = {}));
export var SUPPORTED_CHAIN_IDS = [1, 3, 4, 5, 137, 80001];
export var chainNameIdMap = new Map([
    [ChainNetwork.mainnet, 1],
    [ChainNetwork.ropsten, 3],
    [ChainNetwork.rinkeby, 4],
    [ChainNetwork.polygon, 137],
    [ChainNetwork.mumbai, 80001],
]);
export var chainIdMap = new Map([
    [
        1,
        {
            mainnet: 'ethereum',
            network: ChainNetwork.mainnet,
            color: '#3cc29e',
        },
    ],
    [
        3,
        {
            mainnet: 'ethereum',
            network: ChainNetwork.ropsten,
            color: '#f6c343',
        },
    ],
    [
        4,
        {
            mainnet: 'ethereum',
            network: ChainNetwork.rinkeby,
            color: '#f6c343',
        },
    ],
    [
        137,
        {
            mainnet: 'polygon',
            network: ChainNetwork.mainnet,
            color: '#8247e5',
        },
    ],
    [
        80001,
        {
            mainnet: 'polygon',
            network: ChainNetwork.mumbai,
            color: '#de4437',
        },
    ],
]);
