var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./ActiveSpinner.module.sass";
var ActiveSpinner = function (_a) {
    var size = _a.size, color = _a.color;
    var containerStyle = {
        width: size,
        height: size,
    };
    var itemStyle = {
        width: size,
        height: size,
        borderColor: "".concat(color, " transparent transparent transparent"),
    };
    return (_jsxs("div", __assign({ className: styles.loading, style: containerStyle }, { children: [_jsx("div", { style: itemStyle }, void 0), _jsx("div", { style: itemStyle }, void 0), _jsx("div", { style: itemStyle }, void 0), _jsx("div", { style: itemStyle }, void 0)] }), void 0));
};
export default ActiveSpinner;
